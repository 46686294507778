import {NoSsr} from '@/components/common-components';
import {serverSideTranslations} from 'next-i18next/serverSideTranslations';
import {useTranslation} from 'next-i18next';
import {observer} from 'mobx-react-lite';
import Head from 'next/head';
import {Fragment, useEffect} from 'react';
import {ErrorBoundary} from '../../components/ErrorBoundary';
import {resetStore} from '@/store/root-store';
import {useRouter} from 'next/router';
import {getLocation} from '@/utils/string';
import {LoginPage} from '@/components/dashboard/account/login';


const Login = observer(({...props}) => {
  const {t} = useTranslation('common');
  const router = useRouter();

  useEffect(()=>{
    if (router?.pathname == '/login') {
      resetStore();
      localStorage.removeItem('impersonate');
    }
  }, []);
  return (
    <Fragment>
      <Head>
        <title>{t('login')} | {getLocation(location)} </title>
      </Head>
      <NoSsr>
        <ErrorBoundary>
          <LoginPage {...props} />
        </ErrorBoundary>
      </NoSsr>
    </Fragment>
  );
});


export const getStaticProps = async ({locale}) => ({
  props: {
    ...await serverSideTranslations(locale, ['common', 'components', 'login', 'settings']),
  },
});


export default Login;
