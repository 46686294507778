import Image from 'next/image';
import React from 'react';
import styled from 'styled-components';
import {Button} from 'antd';

const GoogleAuthButton = ({onClick, style, buttonTitle='Continue with Google', responsiveMedia}: {onClick?: (value?: any) => void; style?: any; buttonTitle?: string; responsiveMedia?: string}) => {
  return (
    <GoogleButton onClick={onClick} style={style} responsiveMedia={responsiveMedia}>
      <div>{buttonTitle}</div>
      <Image alt='google_icon' src='/img/registerBg/google_icon.svg' width={20} height={20} />
    </GoogleButton>
  );
};

export default GoogleAuthButton;

const GoogleButton = styled(Button)<{responsiveMedia?: string}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 53px;
  border-radius: 6px;
  font-family: inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 20.91px;
  text-align: left;
  color: #24262A;
  &:hover, &:focus {
    color: #24262A !important;
    border-color: #fff !important;
  }
  @media screen and (max-width: 1030px) {
    height: 45px !important;
    font-size: 14px;
  }
  ${p => p?.responsiveMedia ? p?.responsiveMedia : '@media screen and (max-width: 800px) {height: 40px !important; font-size: 12px !important;}'}
`;
